import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Jumbotron, Container, Row, Col, Image, Button } from 'react-bootstrap';
import '../stylesheets/About.css';

export default class About extends Component {
	render() {
		return (
			<Container>
				<Jumbotron>
				  <h2>About</h2>
                  <hr/>
                  <h5>Summary</h5>
                  <p>My name is Lukas Jenks; I'm a Software Engineer full-time and graduate student part-time at the Georgia Institute of Technology. I've been lucky enough to work in development professionally in multiple industries and domains (since I graduated with my B.Sc. in Computer Science), and love what I do. I like to explore the variety of issues applied computer science can solve (see the "Projects" tab of this site), and academically, my current research focuses on applying cognitive science models to deep learning methods.</p>
                  <hr/>
				  <h5>This Site</h5>
				  <p>For those curious, this simple site was made in
				  React.js/Golang, probably my favourite web stack (although Rust has lately become
				  a contender for me too). It's hosted on my DigitalOcean
				  server, along with a few other sites/services I'm running. Here's the source for this
				  site if you're interested in making one like it:
				  </p>
				  <a href="https://github.com/lukasjenks/blog-site">
				  https://github.com/lukasjenks/blog-site
				  </a>
				  <hr/>
				  <h5>Links</h5>
				  <p>My Github:</p>
				  <a href="https://github.com/lukasjenks">
				  https://github.com/lukasjenks
				  </a>
				  <p>My LinkedIn:</p>
				  <a href="https://linkedin.com/in/lukasjenks">
				  https://linkedin.com/in/lukasjenks
				  </a>
				  <hr/>
                  <h5>Contact</h5>
                  <p>Contact me on LinkedIn: </p>
				  <a href="https://linkedin.com/in/lukasjenks">
				  https://linkedin.com/in/lukasjenks
				  </a>
				  <p>Or email: </p>
				  <a href="mailto:lukasjenks@gmail.com">lukasjenks@gmail.com</a>
				</Jumbotron>
			</Container>
		);
	}
}
